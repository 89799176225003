<template>
  <div class="relative flex items-center border border-gray-300 bg-white">
    <input
        v-model="searchQuery"
        @keyup.enter="onEnter"
        @keyup.esc="onAbort"
        @keyup="onInput"
        type="text"
        placeholder="Suche..."
        class="px-4 py-2 w-full text-sm text-gray-900 placeholder-gray-400 focus:outline-none focus:border-emerald-500"
    />
    <button v-if="searchSent" @click="onAbort" class="p-2 hover:bg-gray-100 focus:outline-none">
      <AbortIcon class="h-5 w-5 text-gray-400" />
    </button>
    <button v-else @click="onEnter" class="p-2 hover:bg-gray-100 focus:outline-none" :disabled="(searchQuery||'').length<3">
      <SearchIcon class="h-5 w-5 text-gray-400" />
    </button>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { MagnifyingGlassIcon } from "@heroicons/vue/24/outline";
import { XMarkIcon } from "@heroicons/vue/24/outline";

export default defineComponent({
  components: {
    SearchIcon:MagnifyingGlassIcon,
    AbortIcon:XMarkIcon
  },
  data() {
    return {
      searchQuery: "",
      searchSent:false,
      lastSentQuery: ""
    };
  },
  props: {
    searchValueBase64: {
      type: String,
      default: ""
    }
  },
  mounted() {
    if (this.searchValueBase64) {
      try { this.searchQuery = atob(this.searchValueBase64); } catch (err) { /**/ }
      this.searchSent = true;
      this.lastSentQuery = this.searchQuery;
    }
  },
  methods: {
    onEnter() {
      if ((this.searchQuery||"").length>2) {
        this.$emit("search", this.searchQuery);
        this.searchSent = true;
        this.lastSentQuery = this.searchQuery;
      }
      else if (!this.searchQuery)
        this.onAbort();
    },
    onAbort() {
      this.$emit("search", null);
      this.searchQuery = "";
      this.searchSent = false;
    },
    onInput() {
      this.searchSent = (this.lastSentQuery===this.searchQuery);
    },
  },
});
</script>
