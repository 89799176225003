<template>
  <Transition name="modal">
    <div v-if="show" class="modal-mask" @click="$emit('close')" ref="modalMask">
      <div class="relative modal-container md:max-w-[80%]" @click.stop.self ref="modalContainer">
        <div class="absolute top-0 right-0 hover:text-emerald-500 cursor-pointer">
          <XMarkIcon @click="$emit('close')" class="h-9 w-9"/>
        </div>
        <div class="modal-header">
          <slot name="header"></slot>
        </div>
        <div class="modal-body w-100">
          <slot name="body"></slot>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import {XMarkIcon} from "@heroicons/vue/24/solid";
import {ref, watch} from "vue";
import {bindKey} from "@rwh/keystrokes";
export default {
  components : {
    XMarkIcon
  },
  props: {
    show: Boolean,
  },
  setup(props) {
    let delayedShow = ref(false);
    const toggleBodyScroll = (scroll) => {
      document.body.classList[scroll ? "add" : "remove"]("modal-open");
    }
    const onToggle = (show) => {
      toggleBodyScroll(show);
      setTimeout(()=>{
        delayedShow.value = show;
      });
    }
    watch(()=>props.show,(show)=>{
      onToggle(show);
    });
    return {delayedShow};
  },
  created() {
    document.documentElement.style.setProperty("--scrollbarWidth",`${window.innerWidth - document.documentElement.clientWidth}px`);
    bindKey("Escape",()=>this.$emit("close"));
  },
  methods: {
    toggleContainerClass(className) {
      let classList = this.$refs.modalContainer.classList;
      classList[classList.contains("flip") ? "remove" : "add"](className);
    },
    toggleMaskClass(className) {
      let classList = this.$refs.modalMask.classList;
      classList[classList.contains("flip") ? "remove" : "add"](className);
    }
  }
}
</script>

<style>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  transition: opacity 0.3s ease;
  overflow-y: hidden;
  padding:2rem 0;
}

body.modal-open .modal-mask {
  overflow-y: scroll;
}

.modal-container {
  margin: auto;
  padding: 20px 30px;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  max-width: 1000px;
}

.modal-container .card {
  min-height: 200px;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}
.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>