import { createApp } from 'vue'
import App from './App.vue'
import router from '@/router';
import './styles/tailwind.css';
import './registerServiceWorker'

const app = createApp(App);
app.use(router)
router.isReady().then(()=>{
    app.mount('#app')
});
