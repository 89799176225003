<template>
  <Transition name="modal">
    <div v-if="show" class="modal-mask flex content-center justify-center items-center" ref="modalMask">
      <div class="modal-container w-full flex-grow-1 flex justify-center items-center relative md:max-w-[80%] cursor-grab" ref="modalContainer"
       @touchstart="handleTouchStartExt"
       @mousedown="handleTouchStartExt"
       @touchend="handleTouchEndExt"
       @mouseup="handleTouchEndExt">
        <div class="back top-0 w-full" @click.stop.self>
          <div class="bg-white card" ref="backContent">
            <div class="absolute top-0 right-0 hover:text-emerald-500 cursor-pointer">
              <XMarkIcon @click="$emit('close')" class="h-6 w-6"/>
            </div>
            <div class="modal-body w-100 prose prose-a:no-underline">
              <slot name="back"></slot>
            </div>
          </div>
        </div>
        <div class="front absolute" @click.stop.self>
          <div class="bg-white card flex items-center justify-center">
            <div class="absolute top-0 right-0 hover:text-emerald-500 cursor-pointer">
              <XMarkIcon @click="$emit('close')" class="h-6 w-6"/>
            </div>
            <div class="modal-body w-100">
              <slot name="front"></slot>
            </div>
          </div>
        </div>
      </div>
      <div class="fixed flex justify-center self-end transition-all" ref="testUI">
        <div class="text-emerald-500 cursor-pointer flex flex-col-reverse items-center">
          <slot name="progress"></slot>
          <div v-if="flipped" class="flex flex-col items-center scale-[1.5] p-2 bg-opacity-90 bg-white z-30">
            <div>Gewusst?</div>
            <div class="flex flex-row items-center mt-2">
              <button class="rounded-none border-emerald-500 border text-emerald-500 bg-white py-2 px-3 flex flex-row items-center" @click="onYes">JA</button>
              <button class="ml-2 rounded-none text-red-500 border-red-500 border bg-white py-2 px-3 flex flex-row items-center" @click="onNo">NEIN</button>
            </div>
          </div>
          <div ref="flipIcons" class="transition-all duration-500 my-2">
            <IconFlipLeft v-if="!flipped" @click="clicked=true;flip($event)" class="h-6 w-6 scale-[2.5]"/>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import FlippableCardModal from "@/components/FlippableCardModal.vue";
export default {
  extends:FlippableCardModal,
  setup(props) {
    return {...FlippableCardModal.setup(props)};
  },
  beforeUnmount() {
    clearTimeout(this.handleClickthrough)
  },
  data: () => ({
    clicked:false,
    debounceClickthroughStart:100,
    debounceClickthroughEnd:1000,
    handleClickthrough: null
  }),
  methods: {
    ...FlippableCardModal.methods,
    onYes() {
      this.flipped=false;
      this.clicked=false;
      this.$emit('success')
    },
    onNo() {
      this.flipped=false;
      this.clicked=false;
      this.$emit('fail')
    },
    handleTouchStartExt(event) {
      if (this.$refs.testUI) {
        clearTimeout(this.handleClickthrough);
        this.handleClickthrough = setTimeout(()=>{
            this.$refs.testUI.classList.add("click-through"),this.debounceClickthroughStart
        });
      }
      this.handleTouchStart(event);
    },
    handleTouchEndExt() {
      if (this.$refs.testUI) {
        clearTimeout(this.handleClickthrough);
        this.handleClickthrough = setTimeout(()=>this.$refs.testUI.classList.remove("click-through"),this.debounceClickthroughEnd);
      }
      this.handleTouchEnd()
    },
  }
}
</script>

<style scoped>
.modal-container {
  background-color: transparent;
  box-shadow: none;
  perspective: 1000px;
  margin: 0;
  transition: all 0.2s linear;
  opacity: 1;
  max-width: 1000px;
  user-select: none;
}
@media (max-width: 767px) {
  .modal-container {
    padding: 0;
  }
}
.modal-container>.front>div , .modal-container>.back>div {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  padding: 20px 30px;
}
.modal-container>.back {
  max-height: calc(100vh - 40px);
}
.modal-container>.front>div {
  width:var(--contentWidth,auto);
  height:var(--contentHeight,auto);
  max-height: calc(100vh - 40px);
}
.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  opacity: 0;
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
}
</style>