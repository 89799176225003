export function shuffleArray(data,args={}) {
    const maxTime = args["maxTime"] || 200;
    const maxIter = args["maxIter"] || data.length*2;
    return new Promise(resolve=> {
        let timeStart = new Date().getTime();
        (function next(i,data){
            let time = new Date().getTime();
            if (time-timeStart<maxTime && i<maxIter) {
                let r = Math.floor(Math.random()*data.length);
                data.unshift(data.splice(r,1)[0]);
                setTimeout(()=>next(i+1,data));
            }
            else
                resolve(data);
        })(0,data);
    });
}

export class Leitner {
    objects=[];
    boxes=[];
    key="id";
    async init(options={}) {
        let shuffleBoxes = options["shuffleBoxes"] || false;
        let numBoxes = options["numBoxes"] || 5;
        let objects = options["objects"] || [];
        this.key = options["key"] || "id";
        this.boxes = Array.from({length:numBoxes}).map(()=>({objects:[]}));
        this.load(objects)
        if (shuffleBoxes)
            await this.shuffle();
        return this;
    }
    load(objects) {
        for (let i=0;i<objects.length;i++) {
            let obj = objects[i];
            this.boxes[Math.min(obj.box || 0,this.boxes.length-1)].objects.push(obj);
        }
        this.objects = objects;
    }
    save() {
        return this.objects;
    }
    stats() {
        let remaining = (this.boxes[0].objects||[]).length;
        let total = this.boxes.reduce((a,b)=>a+(b.objects||[]).length,0);
        let seen = this.boxes.reduce((a,b)=>a+(b.objects||[]).filter(o=>o.timestamp).length,0);
        let progress = total-remaining;
        return {
            progress, total, seen
        }
    }
    async shuffle(boxes=this.boxes) {
        await Promise.all(boxes.map(async box=>await shuffleArray(box.objects)));
    }
    shiftObject(obj,d) {
        let timestamp = new Date().getTime();
        let stack = [], n=0, to=0;
        stack.push(this.boxes[0]);
        while (stack.length>0) {
            let box = stack.pop();
            let i = box.objects.findIndex((element)=>element[this.key]===obj[this.key])
            if (i!==-1){
                if (n+d<this.boxes.length) {
                    to = Math.max(n+d,0);
                    let _obj = box.objects.splice(i,1)[0];
                    _obj.timestamp = timestamp;
                    this.boxes[to].objects.push(_obj);
                }
            }
            else
                stack.push(this.boxes[++n]);
        }
        return {box:to,timestamp};
    }
    moveBack(obj) {
        return this.shiftObject(obj,1);
    }
    moveToFront(obj) {
        return this.shiftObject(obj,-this.boxes.length);
    }
    succeed(obj) {
        return this.moveBack(obj);
    }
    fail(obj) {
        return this.moveToFront(obj)
    }
    getNext() {
        var obj = {};
        var i=0;
        while (i<this.boxes.length && this.boxes[i].objects.length===0)
            i++;
        if (i<this.boxes.length)
            obj = this.boxes[i].objects[0];
        return {
            obj:obj,
            fromBox:i
        };
    }
}