<template>
  <div class="bg-gray-200 relative progress-bar">
    <div
        :style="progressBarStyle"
        class="h-full absolute left-0 top-0">
    </div>
    <slot name="label"></slot>
  </div>
</template>

<script>
export default {
  name: 'ProgressBar',
  props: {
    colorStart: {
      type: String,
      default:"#dc2626"
    },
    colorMid: {
      type: String,
      default:"#ffff00"
    },
    colorEnd: {
      type: String,
      default:"#10b981"
    },
    itemsDone: {
      type: Number,
      required: true
    },
    totalItems: {
      type: Number,
      required: true
    }
  },
  computed: {
    progressPercentage() {
      return (this.itemsDone / this.totalItems) * 100;
    },
    currentColor() {
      const startRGB = this.hexToRgb(this.colorStart);
      const midRGB = this.hexToRgb(this.colorMid);
      const endRGB = this.hexToRgb(this.colorEnd);
      let r, g, b;

      if (this.progressPercentage <= 30) {
        const percent = this.progressPercentage / 30;
        r = Math.round(startRGB.r + percent * (midRGB.r - startRGB.r));
        g = Math.round(startRGB.g + percent * (midRGB.g - startRGB.g));
        b = Math.round(startRGB.b + percent * (midRGB.b - startRGB.b));
      } else {
        const percent = (this.progressPercentage - 30) / 70;
        r = Math.round(midRGB.r + percent * (endRGB.r - midRGB.r));
        g = Math.round(midRGB.g + percent * (endRGB.g - midRGB.g));
        b = Math.round(midRGB.b + percent * (endRGB.b - midRGB.b));
      }
      return `rgb(${r}, ${g}, ${b})`;
    },
    progressBarStyle() {
      return {
        width: `${this.progressPercentage}%`,
        backgroundColor: this.currentColor
      };
    }
  },
  methods: {
    hexToRgb(hex) {
      const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
      hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);

      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      } : null;
    }
  }
}
</script>

<style>
.shadow-outline {
  text-shadow: 1px 1px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000;
}
.progress-bar {
  width: calc(var(--contentWidth,auto) - 1rem);
  max-width: 600px;
}
</style>
