<template>
  <div class="cross-container flex flex-row justify-center items-center" v-if="isVisible" :key="renderKey">
    <svg class="cross stroke-red-600 z-[10000] w-[80vw] h-[80vh]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
      <circle class="cross-circle" cx="26" cy="26" r="25" fill="none"/>
      <path class="cross-line1" fill="none" d="M16,16 L36,36"/>
      <path class="cross-line2" fill="none" d="M16,36 L36,16" @animationend="handleAnimationEnd"/>
    </svg>
  </div>
</template>
<script>
export default {
  name: 'XmarkComponent',
  props: {
    isVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      renderKey: 0
    }
  },
  watch: {
    isVisible(newVal) {
      if (newVal) {
        this.renderKey++;
      }
    }
  },
  methods: {
    handleAnimationEnd() {
      this.$emit("animationComplete");
    }
  }
}
</script>
<style>
.cross {
  width: 100%;
  height: 100%;
  fill: none;
  stroke-width: 2;
  stroke-miterlimit: 10;
}

.cross-circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.cross-line1, .cross-line2 {
  stroke-dasharray: 30;
  stroke-dashoffset: 30;
}

.cross-line1 {
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.7s forwards;
}

.cross-line2 {
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.9s forwards;
}
@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
</style>