<template>
  <div class="relative">
<!--    <button @click="gotoTest" class="start-test-btn absolute top-[-60px] right-0 rounded-none bg-emerald-500 text-white p-2 m-2 flex flex-row items-center">
      <IconTest class="h-6 w-6"/><div class="hidden md:flex md:flex-col items-start"><div>TEST</div><div style="font-size: xx-small; line-height: 0.5">STARTEN</div></div>
    </button>-->
    <div class="flex flex-row absolute top-[-60px] left-0 m-2">
      <SearchInput :searchValueBase64="searchTermBase64" @search="search" ></SearchInput>
    </div>
    <EllipsisDropdownOptions :key="settingsKey">
      <template #options>
        <div class="flex flex-row items-center mt-1 ml-2"><ToggleSwitch v-model="showCards" @update:modelValue="onUpdateSettings('showCards')"/><div class="ml-3">Karten</div></div>
        <div class="flex flex-row items-center mt-1 ml-2"><ToggleSwitch v-model="showSchemas" @update:modelValue="onUpdateSettings('showSchemas')"/><div class="ml-3">Schemata</div></div>
        <div class="flex flex-row items-center mt-1 ml-2"><ToggleSwitch v-model="enlargeContent" @update:modelValue="onUpdateSettings('enlargeContent')"/><div class="ml-3">Grösser</div></div>
        <div class="py-1 flex flex-row justify-end" role="none">
          <button @click="gotoTest" class="start-test-btn rounded-none bg-emerald-500 text-white p-2 mt-1 flex flex-row items-center">
            <IconTest class="h-6 w-6"/><div class="flex flex-col items-start"><div>TEST</div><div style="font-size: xx-small; line-height: 0.5">STARTEN</div></div>
          </button>
        </div>
      </template>
    </EllipsisDropdownOptions>
    <div class="flex flex-col justify-center content-center items-center t-header">
      <div class="text-left w-[300px] md:w-auto">
        <div class="text-4xl flex flex-row items-center cursor-pointer" @click="goBack()">
          <IconBack class="h-6 w-6 ml-[-8px]"/>
          <div>{{ combinedTitle }}</div>
        </div>
        <div class="text-xl mt-5">{{selectedCategory.Title}}</div>
      </div>
    </div>
    <div :class="'flex flex-row flex-wrap mt-5 t-content '+(enlargeContent ? 'cards-large' : '')">
      <div v-for="s of listCombinedFiltered" v-bind:key="s.id"
           :class="'relative category-card m-2 cursor-pointer max-w-md mx-auto bg-white shadow-md overflow-hidden transition \
           duration-1000 ease-out transform w-[300px] min-h-[180px] flex justify-center items-center text-center hover:shadow-2xl hover:shadow-'+s.hoverColor"
           @click="s.isSchema ? showSchemaContent(s.id) : showCardContent(s.id)">
        <div class="absolute top-0 right-0 hover:text-emerald-500" v-if="s.isSchema">
          <IconSchema @click="gotoCards($event,s.id)" :class="enlargeContent ? 'h-9 w-9' : 'h-6 w-6'"/>
        </div>
        <div :class="enlargeContent ? 'p-7 text-6xl' : 'p-5 text-3xl'">
          <div :class="'tracking-wide text-stone-700 font-semibold '+(enlargeContent ? 'text-xl' : 'text-sm')">{{ s.Title }}</div>
        </div>
      </div>
      <div v-if="init && !listCombinedFiltered.length" class="m-2">Keine Daten verfügbar</div>
    </div>
    <Teleport to="body">
      <FlippableCardModal :show="displayCard!==false" @close="displayCard = false" @push="onPush"
                          @prevCard="displayPrevCard" @nextCard="displayNextCard"
                          ref="cardModal" :autoFlip="displayCard.autoFlip" :immediateFlip="true" :hasPrev="displayCardHasPrev" :hasNext="displayCardHasNext" :transition="displayCard.transition">
        <template #front>
          <div class="grow flex justify-center items-center">
            <div v-html="(displayCard||{}).Front||''"></div>
          </div>
        </template>
        <template #back>
          <div v-html="(displayCard||{}).Back||''"></div>
          <div v-show="(displayCard||{}).Literature||false">
            <div class="inline-flex flex-row text-gray-700 mt-2" @click="displayLiterature=!displayLiterature"><LiteratureIcon class="w-6 h-6"/><div>Literatur</div></div>
            <div v-html="(displayCard||{}).Literature||''" v-show="displayLiterature"></div>
          </div>
        </template>
      </FlippableCardModal>
    </Teleport>
  </div>
</template>

<script>
import {ChevronLeftIcon,BoltIcon} from "@heroicons/vue/24/solid";
import SchemasService from "@/schemas.service";
import CategoriesService from "@/categories.service";
import {ArrowUpRightIcon} from "@heroicons/vue/20/solid";
import {goBack} from "@/utils";
import CardsService from "@/cards.service";
import FlippableCardModal from "@/components/FlippableCardModal.vue";
import EllipsisDropdownOptions from "@/components/EllipsisDropdownOptions.vue";
import ToggleSwitch from "@/components/ToggleSwitch.vue";
import {db} from "@/db";
import CardsView from "@/components/CardsView.vue";
import pastellFarben from "@/pastellfarben";
import {BookOpenIcon} from "@heroicons/vue/24/outline";
import SearchInput from "@/components/SearchInput.vue";
export default {
  name: 'SchemasCardsView',
  extends: CardsView,
  components: {
    SearchInput,
    ToggleSwitch,
    EllipsisDropdownOptions,
    FlippableCardModal,
    IconSchema:ArrowUpRightIcon,
    IconBack:ChevronLeftIcon,
    IconTest:BoltIcon,
    LiteratureIcon:BookOpenIcon
  },
  props: {
    categoryId: Number,
    searchTermBase64: String
  },
  data:()=>{
    return {
      ...CardsView.data.call(this),
      listSchemas:[],
      listCards:[],
      listCombined:[],
      selectedCategory:{},
      init:false,
      displaySchemaModal:false,
      displayCard:false,
      settingsKey:1
    }
  },
  methods: {
    ...CardsView.methods,
    async onCreated(searchTerm=false) {
      await this.loadSettings();
      this.selectedCategory = (await new CategoriesService().getCategory(this.categoryId))||{};
      if (searchTerm!==null && this.searchTermBase64)
        try { searchTerm = atob(this.searchTermBase64); } catch (err) { /**/ }
      if (searchTerm) {
        this.listSchemas = (await new SchemasService().getSchemasBySearchTerm(searchTerm,this.categoryId));
        this.listCards = (await new CardsService().getCardsBySearchTerm(searchTerm,this.categoryId));
      }
      else {
        this.listSchemas = (await new SchemasService().getSchemas(this.categoryId));
        this.listCards = (await new CardsService().getCardsByCategory(this.categoryId));
      }
      let pastellFarbenKeys = Object.keys(pastellFarben);
      this.listCombined = [...this.listSchemas.map(item=>({...item,isSchema:true})),...this.listCards].map(item=>{
        let art = item.Title.match(/Art\.?\s+([^\s]+)/);
        let rnd = Math.floor(Math.random()*pastellFarbenKeys.length);
        return {
          ...item,
          sortKey:art ? parseInt(art[1]) : Infinity,
          hoverColor:pastellFarbenKeys[rnd]
        }
      });
      this.listCombined.sort((a,b) => {
        const keyA = a.sortKey;
        const keyB = b.sortKey;
        if (isNaN(keyA) && isNaN(keyB))
          return 0;
        if (isNaN(keyA))
          return 1;
        if (isNaN(keyB))
          return -1;
        return keyA - keyB;
      });

      this.listCards = this.listCombined.filter(item=>!item.isSchema);
      this.init = true;
    },
    goBack,
    gotoCards(e,schemaId) {
      this.$router.push(`/schemata/${schemaId}/cards`);
    },
    gotoTest() {
      this.$router.push(`/test/${this.categoryId}`);
    },
    getCardIndex(id) {
      let listAll = this.listCombined.filter(item=>this.showSchemas && item.isSchema || this.showCards && !item.isSchema);
      return listAll.findIndex(({id:_id})=>_id===id)
    },
    removeTempCards() {
      let i=0;
      while (i<this.listCombined.length) {
        if (this.listCombined[i].isTemp)
          this.listCombined.splice(i,1);
        else
          i++;
      }
    },
    displayPrevCard(autoFlip=false) {
      let ix = this.getCardIndex(this.displayCard.id);
      if (ix>0) {
        let currentIsTemp = this.listCombined[ix].isTemp;
        if (currentIsTemp)
          this.listCombined.splice(ix,1);
        this.displayCard = false;
        let listAll = this.listCombined.filter(item=>this.showSchemas && item.isSchema || this.showCards && !item.isSchema);
        let item = listAll[ix-1];
        setTimeout(()=>{
          item.isSchema ? this.showSchemaContent(item.id,currentIsTemp || autoFlip,"prev") : this.showCardContent(item.id,currentIsTemp || autoFlip,"prev")
        });
      }
    },
    displayNextCard(autoFlip=false) {
      let ix = this.getCardIndex(this.displayCard.id);
      let listAll = this.listCombined.filter(item=>this.showSchemas && item.isSchema || this.showCards && !item.isSchema);
      if (ix<listAll.length-1) {
        let currentIsTemp = this.listCombined[ix].isTemp;
        this.displayCard = false;
        let item = listAll[ix+1];
        if (currentIsTemp && !item.isTemp)
          this.removeTempCards();
        setTimeout(()=>{
          item.isSchema ? this.showSchemaContent(item.id,autoFlip,"next") : this.showCardContent(item.id,autoFlip,"next")
        });
      }
    },
    onUpdateSettings(key) {
      db.settings.put({key,val:this[key]});
    },
    async search(searchTerm) {
      let baseUrl = false;
      try { ([baseUrl,] = this.$route.path.match(/\/[^/]+\/\d+/)) } catch (err) {/**/}
      if (baseUrl) {
        this.$router.replace(`${baseUrl}/${searchTerm!==null ? btoa(searchTerm) : ''}`);
      }
      this.onCreated(searchTerm);
    },
    async onPush(href) {
      let [,type,id] = href.match(/#([^_]+)_(\d+)/);
      let data = 0;
      if (type === "card")
        data = {...await new CardsService().getCardContent(id),isTemp:true};
      else if (type === "schema")
        data = {...await new SchemasService().getSchema(id),isSchema:true,isTemp:true};
      let ix = this.getCardIndex(this.displayCard.id);
      if (data && ix>-1) {
        this.listCombined.splice(ix+1,0,data)
        this.displayNextCard(true)
      }
    }
  },
  computed: {
    displayCardHasPrev() {
      return this.getCardIndex(this.displayCard.id)>0;
    },
    displayCardHasNext() {
      let listAll = this.listCombined.filter(item=>this.showSchemas && item.isSchema || this.showCards && !item.isSchema);
      return this.getCardIndex(this.displayCard.id)<listAll.length-1;
    },
    listCombinedFiltered() {
      return this.listCombined.filter(item=>(this.showSchemas && item.isSchema || this.showCards && !item.isSchema) && !item.isTemp);
    },
    combinedTitle() {
      return [...this.showSchemas ? ["Schemata"] : [],...this.showCards ? ["Karten"] : []].join(" + ") || "Inhalte";
    }
  }
}
</script>

<style>
.category-card {
  min-width: 300px;
}
body.modal-open .start-test-btn {
  display: none;
}
</style>