module.exports = {
  "pastellrosa": "#FFD1DC",
  "pastellblau": "#A2CFFE",
  "pastellgelb": "#FFF8A9",
  "pastellgruen": "#B5EAD7",
  "pastelllila": "#D6BCFA",
  "pastellorange": "#FFB347",
  "pastelltuerkis": "#96DED1",
  "pastellpfirsich": "#FFE5B4",
  "pastellmint": "#CBF1F5",
  "pastellkoralle": "#FF7F50"
};