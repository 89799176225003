<template>
  <div
      @click="toggle"
      class="relative inline-block w-10 h-4 align-middle select-none transition duration-200 ease-in cursor-pointer"
      :class="[value ? 'bg-emerald-500' : 'bg-gray-400']"
  >
    <span
        class="absolute inline-block w-6 h-6 mt-[-0.25rem] bg-white shadow transform transition ease-in duration-200 border cursor-pointer"
        :class="value ? 'translate-x-6  border-emerald-500' : 'translate-x-0 border-gray-400'"
    ></span>
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  props: {
    modelValue: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const value = ref(props.modelValue);

    const toggle = () => {
      value.value = !value.value;
      emit('update:modelValue', value.value);
    };

    return { value, toggle };
  },
};
</script>
