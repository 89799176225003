import router from "@/router";
export async function goBack() {
    router.isBack = true;
    router.back();
}
export function autoLink(str) {
    // BGE
    const bgeReaderUrl = "https://www.elt.uzh.ch/bgeReader/";
    const listRegex = [/(BGE\s*)?(\d+)\s+([IVabcdABCD]+)\s+(\d+)(\s+[f]{1,2}\.)?[,;.]?\s+E\.?\s+\w+/, /(BGE\s*)?(\d+)\s+([IVabcdABCD]+)\s+(\d+)(\s+[f]{1,2}\.)?/, /(BGE\s*)?(\d\w[_.]{1}\d{1,3}\/2\d{3})/];
    let parts = [];
    let listMatches = [];
    for (let r of listRegex) {
        let matches = r.exec(str);
        if (matches)
            listMatches.push(matches)
    }
    while (listMatches.length) {
        listMatches = listMatches.sort((a, b) => {
            let diff = a.index - b.index; // nearer first
            if (diff !== 0)
                return diff;
            else
                return b[0].length - a[0].length; // longer first
        });
        let nearest = listMatches[0];
        parts.push(str.substr(0,nearest.index));
        parts.push(`<a href="${bgeReaderUrl}?q=${nearest[0]}" target="_blank">&#8599;&nbsp;${nearest[0]}</a>`);
        str = str.substr(nearest.index+nearest[0].length);

        listMatches = [];
        for (let r of listRegex) {
            let matches = r.exec(str);
            if (matches)
                listMatches.push(matches)
        }
    }
    parts.push(str);
    str = parts.join("");

    // Artikel
    let mapGesetze = {
        stgb:"https://www.fedlex.admin.ch/eli/cc/54/757_781_799/de",
        or:"https://www.fedlex.admin.ch/eli/cc/27/317_321_377/de"
    }
    for (let gesetz in mapGesetze) {
        str = str.replace(new RegExp(`\\bArt\\.?\\s+(\\d+)([a-zA-Z]*)\\s+((Abs\\.?\\s+\\d+(\\s+(und)\\s+\\d+)?)\\s)?((Ziff\\.?\\s+\\d+)\\s)?((Satz\\.?\\s+\\d+)\\s)?((lit\\.?\\s+\\w+)\\s)?(f{1,2}\\.\\s)?${gesetz}`,"gi"),
            function(match,index,spec){
                return `<a href=${mapGesetze[gesetz]}#art_${index}${spec ? `_${spec}` : ""} target="_blank">&#8599;&nbsp;${match}</a>`;
            });
    }
    return str;
}