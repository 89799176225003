import StrapiService from "@/service";

export default class CategoriesService extends StrapiService{
    async getCategories() {
        return this.filterQuery("categories",{
            "pagination[limit]":-1
        });
    }
    async getCategory(categoryId) {
        const list = await this.filterQuery("categories",{
            "pagination[limit]":-1,
            "filters[id][$eq]":categoryId,
        });
        return list.length ? list[0] : null;
    }
}