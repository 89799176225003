<template>
  <div class="absolute right-0 top-[-60px]">
    <div class="md:hidden">
      <button
          type="button"
          @click="toggleDropdown"
          class="p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 focus:outline-none"
      >
        <EllipsisVerticalIcon class="h-6 w-6 ml-[-8px]" style="transform:scale(1.5)"/>
      </button>
    </div>

    <div
        :class="'origin-top-right absolute right-0 mr-2 shadow-lg md:shadow-none bg-white md:bg-transparent ring-1 md:ring-0 ring-black ring-opacity-5 focus:outline-none p-2 z-10 md:block'+(!dropdownOpen ? ' hidden' : '')"
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="menu-button"
        tabindex="-1"
    >
      <slot name="options"></slot>
    </div>
  </div>
</template>

<script>
import EllipsisDropdown from "@/components/EllipsisDropdown.vue";
export default {
  name: 'EllipsisDropdownOptions',
  extends: EllipsisDropdown,
  components: {
  },
  data() {
    return {
      ...EllipsisDropdown.data.call(this)
    }
  },
  methods: {
  }
}
</script>
