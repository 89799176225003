<template>
  <div>
    <div class="flex flex-col justify-center content-center items-center t-header">
      <div class="text-left w-[300px] md:w-auto">
        <div class="text-4xl flex flex-row items-center cursor-pointer" @click="goBack()">
          <IconBack class="h-6 w-6 ml-[-8px]"/>
          <div>Karten</div>
        </div>
        <div class="text-xl mt-5 flex inline cursor-pointer" @click="showSchemaContent(selectedSchema.id)">{{ selectedSchema.Title }}<IconSchema class="h-6 w-6"/></div>
      </div>
    </div>
    <div :class="'flex flex-row flex-wrap mt-5 t-content'+(enlargeContent ? ' cards-large' : '')">
      <div v-for="s of listCards" v-bind:key="s.id"
           class="relative category-card m-2 cursor-pointer max-w-md mx-auto bg-white shadow-md overflow-hidden hover:shadow-lg transition duration-300 ease-out transform hover:-translate-y-1 hover:scale-105 w-[300px] min-h-[120px]"
           @click="showCardContent(s.id)">
        <div :class="enlargeContent ? 'p-7 text-6xl' : 'p-5 text-3xl'">
          <div :class="'tracking-wide text-stone-700 font-semibold '+(enlargeContent ? 'text-xl' : 'text-sm')">{{ s.Title }}</div>
        </div>
      </div>
    </div>
    <Teleport to="body">
      <FlippableCardModal :show="displayCard!==false" @close="displayCard = false"
                          @prevCard="displayPrevCard" @nextCard="displayNextCard"
                          ref="cardModal" :autoFlip="displayCard.autoFlip" :immediateFlip="true" :hasPrev="displayCardHasPrev" :hasNext="displayCardHasNext" :transition="displayCard.transition">
        <template #front>
          <div class="grow flex justify-center items-center">
            <div v-html="(displayCard||{}).Front||''"></div>
          </div>
        </template>
        <template #back>
          <div v-html="(displayCard||{}).Back||''"></div>
          <div class="border-l-4 border-l-gray-200 pl-4 mt-2" v-html="(displayCard||{}).Literature||''"></div>
        </template>
      </FlippableCardModal>
    </Teleport>
  </div>
</template>

<script>
import {ChevronLeftIcon} from "@heroicons/vue/24/solid";
import SchemasService from "@/schemas.service";
import CardsService from "@/cards.service";
import FlippableCardModal from "@/components/FlippableCardModal.vue";
import {ArrowUpRightIcon} from "@heroicons/vue/24/outline";
import {goBack} from "@/utils";
import {db} from "@/db";
import {autoLink} from "@/utils";

export default {
  name: 'CardsView',
  components: {
    IconSchema:ArrowUpRightIcon,
    IconBack:ChevronLeftIcon,
    FlippableCardModal
  },
  props: {
    schemaId: Number,
  },
  data:()=>{
    return {
      listCards:[],
      selectedSchema:{},
      displayCard:false,
      showCards:true,
      showSchemas:true,
      enlargeContent:false,
      displayLiterature:false
    }
  },
  async created() {
    this.onCreated();
  },
  methods: {
    goBack,
    async onCreated() {
      await this.loadSettings();
      this.selectedSchema = (await new SchemasService().getSchema(this.schemaId))||{};
      this.listCards = (await new CardsService().getCardsBySchema(this.schemaId));
    },
    getCardIndex(id) {
      return this.listCards.findIndex(({id:_id})=>_id===id)
    },
    async showCardContent(cardId,autoFlip=true,transition="modal") {
      let data = await new CardsService().getCardContent(cardId);
      data.Front = autoLink(data.Front);
      data.Back = autoLink(data.Back);
      data.Literature = autoLink(data.Literature);
      this.displayLiterature = false;
      this.displayCard = {...data,autoFlip,transition};
    },
    async showSchemaContent(schemaId,autoFlip=true,transition="modal") {
      let {id,Title,Content} = (await new SchemasService().getSchema(schemaId));
      this.displayLiterature = false;
      this.displayCard = {id,Front:autoLink(Title),Back:autoLink(Content),isSchema:true,autoFlip,transition};
    },
    displayPrevCard() {
      let ix = this.getCardIndex(this.displayCard.id);
      if (ix>0) {
        this.displayCard = false;
        setTimeout(()=>this.showCardContent(this.listCards[ix-1].id,false,"prev"));
      }
    },
    displayNextCard() {
      let ix = this.getCardIndex(this.displayCard.id);
      if (ix<this.listCards.length-1) {
        this.displayCard = false;
        setTimeout(()=>this.showCardContent(this.listCards[ix+1].id,false,"next"));
      }
    },
    async loadSettings() {
      ({val:this.showCards} = await db.settings.where("key").equals("showCards").first()||{});
      ({val:this.showSchemas} = await db.settings.where("key").equals("showSchemas").first()||{});
      ({val:this.enlargeContent} = await db.settings.where("key").equals("enlargeContent").first()||{});
      if (typeof this.showCards==="undefined")
        this.showCards = true;
      if (typeof this.showSchemas==="undefined")
        this.showSchemas = true;
      if (typeof this.enlargeContent==="undefined")
        this.enlargeContent = false;
      this.settingsKey++;
    }
  },
  computed: {
    displayCardHasPrev() {
      return !this.displayCard.isSchema && this.getCardIndex(this.displayCard.id)>0;
    },
    displayCardHasNext() {
      return !this.displayCard.isSchema && this.getCardIndex(this.displayCard.id)<this.listCards.length-1;
    }
  }
}
</script>

<style>
.category-card {
  min-width: 300px;
}
.modal-container .front, .modal-container .back {
  backface-visibility: hidden;
  transition: transform 0.6s ease;
}
.modal-container .front {
  transform: rotateY(0deg);
}
.modal-container .back {
  transform: rotateY(180deg);
}
.modal-container.flip .front {
  transform: rotateY(-180deg);
}
.modal-container.flip .back {
  transform: rotateY(0deg);
}
.cards-large > .category-card {
  min-width: 500px;
}
@media screen and (max-width: 500px) {
  .cards-large > .category-card {
    min-width: 100vw
  }
}
</style>