import StrapiService from "@/service";
export default class SchemasService extends StrapiService{
    async getSchemas(categoryId=0) {
        return this.filterQuery("schemata",{
            "pagination[limit]":-1,
            ...categoryId>0 ? { "filters[categories][id][$eq]":categoryId } : {},
            "fields[0]":"id",
            "fields[1]":"Title"
        });
    }
    async getSchema(schemaId) {
        const list = await this.filterQuery("schemata",{
            "pagination[limit]":-1,
            "filters[id][$eq]":schemaId,
        });
        return list.length ? list[0] : null;
    }
    async getSchemasBySearchTerm(str,categoryId=0) {
        return this.filterQuery("schemata",{
            "pagination[limit]":-1,
            ...categoryId>0 ? { "filters[categories][id][$eq]":categoryId } : {},
            "filters[$or][0][Title][$containsi]" : str,
            "filters[$or][1][Content][$containsi]" : str
        });
    }
}