<template>
  <Transition :name="transition"
    @touchmove="handleTouchMove"
    @touchend="handleTouchEnd"
    @mousemove="handleTouchMove"
    @mouseup="handleTouchEnd">
    <div v-if="show" class="modal-mask flex content-center justify-center items-center overflow-x-hidden" @click="$emit('close')" ref="modalMask">
      <div class="modal-container w-full flex-grow-1 flex justify-center items-center relative md:max-w-[80%] cursor-grab" ref="modalContainer" :style="isSwiping ? { transform: 'translateX(' + xPos + 'px)', 'transition-duration':'0s' } : ''"
        @touchstart="handleTouchStart"
        @mousedown="handleTouchStart">
        <div class="back top-0 w-full" @click.stop.self>
          <div class="bg-white card" ref="backContent">
            <div class="absolute top-0 right-0 hover:text-emerald-500 cursor-pointer">
              <XMarkIcon @click="$emit('close')" class="h-9 w-9"/>
            </div>
            <div class="modal-body w-100 prose prose-a:no-underline">
              <slot name="back"></slot>
            </div>
          </div>
        </div>
        <div class="front absolute" @click.stop.self>
          <div class="bg-white card flex items-center justify-center">
            <div class="absolute top-0 right-0 hover:text-emerald-500 cursor-pointer">
              <XMarkIcon @click="$emit('close')" class="h-9 w-9"/>
            </div>
            <div class="modal-body w-100">
              <slot name="front"></slot>
            </div>
          </div>
        </div>
      </div>
      <div class="fixed">
        <div class="absolute top-[40vh] right-0 text-emerald-500 cursor-pointer transition-all duration-200" ref="flipIcons">
          <IconFlipRight v-if="flipped" @click="flip($event)" class="h-6 w-6 scale-[2.5]"/>
          <IconFlipLeft v-else @click="flip($event)" class="h-6 w-6 scale-[2.5]"/>
        </div>

      </div>
      <div v-show="hasPrev" class="fixed left-0 text-emerald-500 cursor-pointer"><IconPrev class="h-6 w-6 scale-[2]" @click.prevent="prevCard"></IconPrev></div>
      <div v-show="hasNext" class="fixed right-0 text-emerald-500 cursor-pointer"><IconNext class="h-6 w-6 scale-[2]" @click.prevent="nextCard"></IconNext></div>
    </div>
  </Transition>
</template>

<script>
import CardModal from "@/components/CardModal.vue";
import {ArrowUturnLeftIcon, ArrowUturnRightIcon} from "@heroicons/vue/24/solid";
import {ref, watch} from "vue";
import {ChevronLeftIcon, ChevronRightIcon} from "@heroicons/vue/24/outline";
import {bindKey} from "@rwh/keystrokes";
import FlippableCardModal from "@/components/FlippableCardModal.vue";
export default {
  SWIPE_THRESHOLD:10,
  extends:CardModal,
  data: function() {
    return {
      xPos:0,
      startTouchX: 0,
      isSwiping: false,
      lastTap: {}
    }
  },
  components: {
    IconFlipLeft:ArrowUturnLeftIcon,
    IconFlipRight:ArrowUturnRightIcon,
    IconPrev:ChevronLeftIcon,
    IconNext:ChevronRightIcon
  },
  props:{
    autoFlip:{
      type:Boolean,
      default:false
    },
    immediateFlip: {
      type:Boolean,
      default:false
    },
    hasPrev: {
      type:Boolean,
      default:false
    },
    hasNext: {
      type:Boolean,
      default:false
    },
    transition: {
      type:String,
      default:"modal"
    }
  },
  setup(props, args) {
    let delayedShow = ref(false);
    const backContent = ref(null);
    const modalContainer = ref(null);
    const modalMask = ref(null);
    const flipped = ref(false);
    const toggleBodyScroll = (scroll) => {
      document.body.classList[scroll ? "add" : "remove"]("modal-open");
    }
    const resizeFront = () => {
      document.documentElement.style.setProperty("--contentWidth",`${backContent.value.clientWidth}px`);
      //document.documentElement.style.setProperty("--contentHeight",`${backContent.value.clientHeight}px`);
    }
    let handleDebounce = null;
    const onResize = ()=>{
      if (backContent.value) {
        clearTimeout(handleDebounce);
        handleDebounce = setTimeout(resizeFront,500);
      }
    }
    const getClosestLink = (element) => {
      while (element) {
        if (element.tagName === 'A') {
          return element;
        }
        element = element.parentElement;
      }
      return null;
    };
    const handleClick = (event) => {
      const link = getClosestLink(event.target);
      if (link && (link.getAttribute("href")||"").match(/^#/)) {
        event.preventDefault();
        const emit = args.emit;
        if (emit)
          emit("push",link.getAttribute("href"));
      }
    }
    const flip = () => {
      modalContainer.value.classList.add("flip");
      modalMask.value.classList.add("flip");
    }
    const onToggle = (show) => {
      setTimeout(()=>{
        if (backContent.value) {
          resizeFront();
        }
        if (props.autoFlip && show && modalContainer.value) {
          if (props.immediateFlip)
            flip();
          else
            modalContainer.value.addEventListener("transitionend",flip,{once:true});
          flipped.value = true;
        }
        if (backContent.value) {
          console.log(backContent.value)
          backContent.value.removeEventListener("click",handleClick);
          backContent.value.addEventListener("click",handleClick);
        }
      });
      toggleBodyScroll(show);
      setTimeout(()=>{
        delayedShow.value = show;
      });
      window.removeEventListener("resize",onResize);
      window.addEventListener("resize",onResize);
      if (!show)
        clearTimeout(handleDebounce);
    }
    watch(()=>props.show,(show)=>{
      onToggle(show);
    });
    return {delayedShow,backContent,modalContainer,modalMask,flipped};
  },
  created() {
    bindKey("ArrowLeft",()=>this.prevCard());
    bindKey("ArrowRight",()=>this.nextCard());
    bindKey("Escape",()=>this.$emit("close"));
    bindKey("Enter",()=>this.flip());
    bindKey(" ",()=>this.flip());
  },
  methods: {
    onFlipped() {
      if (this.$refs.modalContainer)
        this.flipped=this.$refs.modalContainer.classList.contains("flip");
      if (this.$refs.flipIcons)
        this.$refs.flipIcons.classList.remove("opacity-0");
    },
    flip(event=null) {
      if (event)
        event.stopImmediatePropagation();
      if (this.$refs.modalContainer) {
        this.$refs.modalContainer.removeEventListener("transitionend",this.onFlipped);
        this.$refs.modalContainer.addEventListener("transitionend",this.onFlipped,{once:true});
        this.toggleContainerClass("flip");
        this.toggleMaskClass("flip");
        this.$refs.modalMask.scrollTop = 0;
        if (this.$refs.flipIcons)
          this.$refs.flipIcons.classList.add("opacity-0");
      }
    },
    prevCard(event) {
      if (event)
        event.stopImmediatePropagation();
      this.$emit("prevCard");
    },
    nextCard(event) {
      if (event)
        event.stopImmediatePropagation();
      this.$emit("nextCard");
    },
    handleTouchStart(event) {
      const time = new Date().getTime();

      let isTouch = (typeof event.touches!=="undefined");
      if (this.lastTap?.isTouch===isTouch) {
        const tapTime = time - this.lastTap?.time||0;
        if (tapTime < 300) {
          this.flip();
          return;
        }
        else
          this.lastTap = {time,isTouch};
      }
      else if (typeof this.lastTap?.isTouch==="undefined")
        this.lastTap = {time,isTouch};

      /*if (["a","svg","path"].indexOf(event.target?.tagName?.toLowerCase())===-1)
        event.preventDefault();*/

      if (event.touches) {
        this.startTouchX = event.touches[0].clientX;
      } else {
        this.startTouchX = event.clientX;
      }
      this.xPos=0;
      this.isSwiping = true;
      this.modalContainer?.classList.remove("cursor-grab");
      this.modalContainer?.classList.add("cursor-grabbing");
    },
    handleTouchMove(event) {
      if (!this.isSwiping)
        return;

      const touchX = event.touches ? event.touches[0].clientX : event.clientX;
      const deltaX = touchX - this.startTouchX;

      const stiffness = 0.3; // Adjust this value to control the stiffness of the spring
      const minThreshold = 5; // Adjust this value to set the minimum threshold for movement
      this.xPos += Math.abs(deltaX) > minThreshold ? deltaX * stiffness : 0;

      // Update the start touch position to track the ongoing movement
      this.startTouchX = touchX;
      this.lastTap = null;
    },
    handleTouchEnd() {
      if (!this.isSwiping)
        return;

      this.isSwiping = false;

      if (Math.abs(this.xPos) > FlippableCardModal.SWIPE_THRESHOLD) {
        if (this.xPos>0)
          this.prevCard();
        else
          this.nextCard();
      } else {
        this.animateBackToOrigin();
      }

      this.modalContainer?.classList.remove("cursor-grabbing");
      this.modalContainer?.classList.add("cursor-grab");
    },
    animateBackToOrigin() {
      this.xPos = 0;
    }
  }
}
</script>

<style scoped>
.modal-container {
  background-color: transparent;
  box-shadow: none;
  perspective: 1000px;
  margin: 0;
  transition: all 0.2s linear;
  opacity: 1;
  max-width: 1000px;
  user-select: none;
}
@media (max-width: 767px) {
  .modal-container {
    padding: 0;
  }
}
.modal-container>.front>div , .modal-container>.back>div {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  padding: 20px 30px;
}
.modal-container>.back {
  max-height: calc(100vh - 40px);
}
.modal-container>.front>div {
  width:var(--contentWidth,auto);
  height:var(--contentHeight,auto);
  max-height: calc(100vh - 40px);
}
.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  opacity: 0;
  transform: scale(0.5);
}

.next-enter-from .modal-container {
  opacity: 0;
  transform: translateX(50vw);
}
.next-leave-to .modal-container {
  opacity: 0;
}
.prev-enter-from .modal-container {
  opacity: 0;
  transform: translateX(-50vw);
}
.prev-leave-to .modal-container {
  opacity: 0;
}
</style>