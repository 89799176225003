<template>
  <div>
    <div class="flex flex-col justify-center content-center items-center">
      <div class="text-left w-[300px] md:w-auto">
        <div class="text-4xl flex flex-row items-center">
          <IconBack class="h-6 w-6 ml-[-8px]" @click="goBack()"/>
          <div>Test</div>
        </div>
        <div class="text-xl mt-5">{{ selectedCategory.Title }}</div>
      </div>
    </div>
    <Teleport to="body">
      <SelfAssessmentCardModal :show="displayCardModal!==false" @close="endTest" ref="cardModal" @success="onSuccess" @fail="onFail" >
        <template #front>
          <div class="grow flex justify-center items-center">
            <div v-html="(displayCardModal||{}).Front||''"></div>
          </div>
        </template>
        <template #back>
          <div v-html="(displayCardModal||{}).Back||''"></div>
          <div v-show="(displayCardModal||{}).Literature||false">
            <div class="inline-flex flex-row text-gray-700 mt-2" @click="displayLiterature=!displayLiterature"><LiteratureIcon class="w-6 h-6"/><div>Literatur</div></div>
            <div v-html="(displayCardModal||{}).Literature||''" v-show="displayLiterature"></div>
          </div>
        </template>
        <template #progress>
          <ProgressBar v-if="stats.total" :total-items="stats.total" :items-done="stats.seen" color-start="#ffffff" color-mid="#ffffff" color-end="#ffffff" class="h-2">
            <template #label>
              <div class="flex items-center justify-end h-full">
                <span class="text-black text-xs z-10 ">{{ Math.floor(100*stats.seen / stats.total) }}%</span>
              </div>
            </template>
          </ProgressBar>
          <ProgressBar v-if="stats.total" :total-items="stats.total" :items-done="stats.progress" class="h-6">
            <template #label>
              <div class="flex items-center justify-center h-full">
                <span class="text-white text-sm z-10 shadow-outline">{{ stats.progress }} / {{ stats.total }}</span>
              </div>
            </template>
          </ProgressBar>
        </template>
      </SelfAssessmentCardModal>
    </Teleport>
    <Teleport to="body">
      <CheckmarkComponent :isVisible="displayIsCorrect" @animationComplete="onAnimationComplete"/>
      <XmarkComponent :isVisible="displayIsIncorrect" @animationComplete="onAnimationComplete"/>
    </Teleport>
  </div>
</template>

<script>
import {ChevronLeftIcon} from "@heroicons/vue/24/solid";
import CardsService from "@/cards.service";
import CategoriesService from "@/categories.service";
import SelfAssessmentCardModal from "@/components/SelfAssessmentCardModal.vue";
import {Leitner} from "@/leitner";
import {db} from "@/db";
import {autoLink, goBack} from "@/utils";
import CheckmarkComponent from "@/components/CheckmarkComponent.vue";
import XmarkComponent from "@/components/XmarkComponent.vue";
import ProgressBar from "@/components/ProgressBarComponent.vue";
import {BookOpenIcon} from "@heroicons/vue/24/outline";
import {singletons} from "@/shared";

export default {
  name: 'SelfAssessmentView',
  components: {
    LiteratureIcon:BookOpenIcon,
    ProgressBar,
    CheckmarkComponent,
    XmarkComponent,
    IconBack:ChevronLeftIcon,
    SelfAssessmentCardModal
  },
  props: {
    categoryId: Number,
  },
  data:()=>{
    return {
      listCards:[],
      selectedCategory:{},
      selectedCards:[],
      displayCardModal:false,
      leitner:null,
      cardId:0,
      displayIsCorrect:false,
      displayIsIncorrect:false,
      maxWait:5000,
      animationIsComplete:false,
      stats:{},
      displayLiterature:false
    }
  },
  async created() {
    this.selectedCategory = (await new CategoriesService().getCategory(this.categoryId))||{};
    this.selectedCards = (await new CardsService().getCardsByCategory(this.categoryId,true))||[];
    console.log(this.selectedCards)
    this.leitner = await new Leitner().init({
      objects:this.selectedCards,
      shuffleBoxes:true
    });
    this.displayNextCard();
  },
  methods: {
    goBack,
    async displayCard(cardId) {
      this.cardId = cardId;
      this.displayCardModal = await new CardsService().getCardContent(cardId);
      if (this.displayCardModal) {
        this.displayCardModal.Front = autoLink(this.displayCardModal.Front);
        this.displayCardModal.Back = autoLink(this.displayCardModal.Back);
        this.displayCardModal.Literature = autoLink(this.displayCardModal.Literature);
      }
      this.displayLiterature = false;
    },
    async displayNextCard() {
      //let ix = Math.floor(Math.random()*this.selectedCards.length);
      //let {id:cardId} = this.selectedCards[ix];
      let {obj:card,fromBox} = this.leitner.getNext();
      console.log(`card ${card.id} from box ${fromBox}`)
      if (card?.id)
        await this.displayCard(card.id);
      this.stats = this.leitner.stats();
    },
    async sendStats(card_id,success) {
      return await fetch(`${location.origin}/stats-api/test`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          user_id:singletons.fingerprint,
          card_id,
          success
        })
      });
    },
    async endTest() {
      this.displayCardModal=false;
      goBack();
    },
    async onSuccess() {
      this.displayIsCorrect = true;
      await this.waitForAnimationToComplete();
      this.displayCardModal=false;
      let {box,timestamp} = this.leitner.succeed({id:this.cardId});
      await db.cards.put({id:this.cardId,box,timestamp});
      console.log(`moved card ${this.cardId} into box ${box}`)
      setTimeout(()=>this.displayNextCard());
      this.displayIsCorrect = false;
      await this.sendStats(this.cardId,true);
    },
    async onFail() {
      this.displayIsIncorrect = true;
      await this.waitForAnimationToComplete();
      this.displayCardModal=false;
      let {box,timestamp} = this.leitner.fail({id:this.cardId});
      await db.cards.put({id:this.cardId,box,timestamp});
      console.log(`moved card ${this.cardId} into box ${box}`)
      setTimeout(()=>this.displayNextCard());
      this.displayIsIncorrect = false;
      await this.sendStats(this.cardId,true);
    },
    onAnimationComplete() {
      this.animationIsComplete = true;
    },
    async waitForAnimationToComplete() {
      this.animationIsComplete = false;
      return new Promise(resolve=>{
        let handleWait = null;
        let handleLimit = setTimeout(()=>{
          clearTimeout(handleWait);
          resolve();
        },this.maxWait);
        let wait = () => {
          if (this.animationIsComplete) {
            clearTimeout(handleLimit)
            setTimeout(()=>resolve(),200);
          }
          else
            handleWait = setTimeout(()=>wait(),100);
        }
        wait();
      })
    },
  }
}
</script>

<style>
.category-card {
  min-width: 300px;
}
.modal-container .front, .modal-container .back {
  backface-visibility: hidden;
  transition: transform 0.6s ease;
}
.modal-container .front {
  transform: rotateY(0deg);
}
.modal-container .back {
  transform: rotateY(180deg);
}
.modal-container.flip .front {
  transform: rotateY(-180deg);
}
.modal-container.flip .back {
  transform: rotateY(0deg);
}
</style>