import {strapiBaseUrl} from "@/settings";
import {db} from "@/db";

const CACHE_TTL=300*1000; // 5 minutes
export default class StrapiService {
  async filterQuery(endpoint,params) {
    let timestamp = new Date().getTime();
    let key=`${endpoint}-${JSON.stringify(params)}`;
    let {data,timestamp:cacheTimestamp} = await db.cache.where("key").equals(key).first()||{};
    if (!data || timestamp-(cacheTimestamp||0)>CACHE_TTL) {
      let response = await fetch(`${strapiBaseUrl}/${endpoint}?${new URLSearchParams(params)}`);
      data = ((await response.json()).data||[]).map(({id,attributes})=>({id,...attributes}));
      db.cache.put({key,data,timestamp});
    }
    return data;
  }
}