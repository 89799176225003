import StrapiService from "@/service";
import {db} from "@/db";
export default class CardsService extends StrapiService {
    async getCardsBySchema(schemaId) {
        return this.filterQuery("cards",{
            "pagination[limit]":-1,
            "filters[schemata][id][$eq]":schemaId,
            "fields[0]":"Literature",
            "fields[1]":"Title"
        });
    }
    async getCardContent(cardId) {
        const list = await this.filterQuery("cards",{
            "filters[id][$eq]":cardId
        });
        return list.length ? list[0] : null;
    }
    async getCardsByCategory(categoryId=0,idOnly=false) {
        let cards = await this.filterQuery("cards",{
            "pagination[limit]":-1,
            ...categoryId>0 ? { "filters[categories][id][$eq]":categoryId } : {},
            ...!idOnly ? {
                "fields[0]":"Literature",
                "fields[1]":"Title"
            } : {
                "fields[0]":"id"
            }
        });
        let stats = await db.cards.toArray();
        let map = {};
        for (let c of stats) {
            map[c.id] = c;
        }
        return cards.map(c=>({...c,...map[c.id]||{}}))
    }
    async getCardsBySearchTerm(str,categoryId=0) {
        return this.filterQuery("cards",{
            "pagination[limit]":-1,
            ...categoryId>0 ? { "filters[categories][id][$eq]":categoryId } : {},
            "filters[$or][0][Front][$containsi]" : str,
            "filters[$or][1][Back][$containsi]" : str
        });
    }
}