import {createRouter, createWebHashHistory, createWebHistory} from "vue-router";
import CategoriesView from "@/components/CategoriesView.vue";
import CardsView from "@/components/CardsView.vue";
import SelfAssessmentView from "@/components/SelfAssessmentView.vue";
import SchemasCardsView from "@/components/SchemasCardsView.vue";

const routes = [
  {
    path: '/',
    redirect: '/categories',
  },
  {
    path: '/categories',
    component: CategoriesView,
  },
  {
    path: '/combined/:categoryId',
    component: SchemasCardsView,
    props:true,
  },
  {
    path: '/combined/:categoryId/:searchTermBase64',
    component: SchemasCardsView,
    props:true,
  },
  {
    path: '/schemata/:schemaId/cards',
    component: CardsView,
    props:true,
  },
  {
    path: '/test/:categoryId',
    component: SelfAssessmentView,
    props:true,
  },
/*  {
    path: '/item/:id',
    component: ItemView,
    props:true
  }*/
]

const router = createRouter({
  history: process.env.IS_ELECTRON ? createWebHashHistory() : createWebHistory(process.env.VUE_APP_CLIENT_BASE),
  routes
})

router.beforeEach((to, from) => {
    to.meta.transition = `slidefade${router.isBack ? "-reverse" : ""}`;
    from.meta.transition = `slidefade${router.isBack ? "-reverse" : ""}`;
    router.isBack = false;
});

export default router
