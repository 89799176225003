<template>
  <div class="relative">
    <div class="flex flex-row absolute top-[-60px] left-0 m-2">
      <SearchInput @search="search" ></SearchInput>
    </div>
    <div class="flex flex-col justify-center content-center items-center t-header">
      <div class="text-left w-[300px] md:w-auto">
        <a class="text-4xl" :href="getLandingUrl">{{appTitle}}</a>
        <div class="text-xl mt-5">Kategorien</div>
      </div>
    </div>
    <div class="flex flex-row flex-wrap mt-5 t-content">
      <div v-for="c of listCategories" v-bind:key="c.name" class="category-card m-2 cursor-pointer max-w-md mx-auto bg-white shadow-md overflow-hidden hover:shadow-lg transition duration-300 ease-out transform hover:-translate-y-1 w-[300px] hover:scale-105"
      @click="gotoSchemasCards($event,c.id)">
        <div class="p-12 text-3xl">
          <div class="uppercase tracking-wide text-sm text-stone-700 font-semibold">{{ c.Title }}</div>
        </div>
      </div>
      <div v-if="!listCategories.length">
        <p class="prose p-2">
          Keine Kategorien verfügbar. Bearbeiten Sie Ihre Lernkarten <a href="/admin/" target="_blank">hier</a>.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import CategoriesService from "@/categories.service";
import SearchInput from "@/components/SearchInput.vue";

export default {
  name: 'CategoriesView',
  components: {SearchInput},
  props: {
    msg: String,
  },
  data:()=>{
    return {
      listCategories:[],
      appTitle:"Lernkarten"
    }
  },
  async created() {
    this.appTitle = process.env.VUE_APP_CLIENT_APP_TITLE || this.appTitle;
    this.listCategories = (await new CategoriesService().getCategories()).sort((a,b) => {
      return a.Title>b.Title ? 1 : -1;
    });
  },
  methods: {
    gotoSchemasCards(e,categoryId) {
      this.$router.push(`/combined/${categoryId}`);
    },
    search(searchTerm) {
      this.$router.push(`/combined/0/${searchTerm!==null ? btoa(searchTerm) : ''}`);
    }
  },
  computed: {
    getLandingUrl() {
      return location.origin.replace(/:\/\//g,"://www.");
    }
  }
}
</script>

<style>
.category-card {
  min-width: 300px;
}
</style>