<template>
  <div class="absolute right-0 top-[-60px]">
    <div>
      <button
          type="button"
          @click="toggleDropdown"
          class="p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 focus:outline-none"
      >
        <EllipsisVerticalIcon class="h-6 w-6 ml-[-8px]"/>
      </button>
    </div>

    <div
        v-show="dropdownOpen"
        class="origin-top-right absolute right-0 mr-2 w-56 shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="menu-button"
        tabindex="-1"
    >
      <div class="py-1" role="none">
        <a
            href="#"
            class="text-gray-700 block px-4 py-2 text-sm"
            role="menuitem"
            tabindex="-1"
            id="menu-item-0"
        >
          Option 1
        </a>
        <a
            href="#"
            class="text-gray-700 block px-4 py-2 text-sm"
            role="menuitem"
            tabindex="-1"
            id="menu-item-1"
        >
          Option 2
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import {EllipsisVerticalIcon} from "@heroicons/vue/24/outline";
export default {
  name: 'EllipsisDropdown',
  components : {
    EllipsisVerticalIcon
  },
  data() {
    return {
      dropdownOpen: false,
    };
  },
  methods: {
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
    },
    closeDropdown() {
      this.dropdownOpen = false;
    },
  },
};
</script>
