<template>
  <router-view v-slot="{ Component, route }">
    <transition :name="route.meta.transition||'slidefade'" mode="out-in">
      <component :is="Component" />
    </transition>
  </router-view>
</template>

<script>
import { getCurrentBrowserFingerPrint } from "@rajesh896/broprint.js";
import {singletons} from "./shared.js";
export default {
  name: 'App',
  async created() {
    singletons.fingerprint = await getCurrentBrowserFingerPrint();
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 60px;
}
body {
  background-color: #efefef;
  overflow-y: scroll;
  overflow-x: hidden;
}
body.modal-open {
  overflow-y: hidden;
  padding-right:var(--scrollbarWidth,0px);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity .2s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.slidefade-enter-active,
.slidefade-leave-active,
.slidefade-reverse-enter-active,
.slidefade-reverse-leave-active {
  transition: all 0.3s ease;
}
.slidefade-enter-from, .slidefade-reverse-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
.slidefade-leave-to, .slidefade-reverse-enter-from {
  transform: translateX(-20px);
  opacity: 0;
}

.click-through {
  pointer-events: none;
  opacity: 0;
}
</style>
