<template>
  <div class="checkmark-container flex flex-row justify-center items-center" v-if="isVisible" :key="renderKey">
    <svg class="checkmark stroke-emerald-500 z-[10000] w-[80vw] h-[80vh]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
      <circle class="checkmark-circle" cx="26" cy="26" r="25" fill="none"/>
      <path class="checkmark-check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" @animationend="handleAnimationEnd"/>
    </svg>
  </div>
</template>
<script>
export default {
  name: 'CheckmarkComponent',
  props: {
    isVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      renderKey: 0
    }
  },
  watch: {
    isVisible(newVal) {
      if (newVal) {
        this.renderKey++;
      }
    }
  },
  methods: {
    handleAnimationEnd() {
      this.$emit("animationComplete");
    }
  }
}
</script>
<style>
.checkmark {
  width: 100%;
  height: 100%;
  fill: none;
  stroke-width: 2;
  stroke-miterlimit: 10;
}

.checkmark-circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark-check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
</style>